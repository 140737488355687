import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Augmented Reality Website for `}<em parentName="h1">{`slanted`}</em></h1>
    <p>{`The first project I want to present to you is an `}<strong parentName="p">{`augmented reality`}</strong>{` web app created for the
`}<strong parentName="p">{`slanted magazine`}</strong>{`, which is used in this edition of the magazine:
`}<a parentName="p" {...{
        "href": "https://www.slanted.de/product/slanted-magazine-37-ai/"
      }}>{`Slanted Magazine #37—AI`}</a>{`.`}</p>
    <p>{`The main topic of that edition is `}<strong parentName="p">{`artificial intelligence`}</strong>{` and while creating the content it also
resulted in some videos.`}</p>
    <p>{`To show the videos slanted wanted a web app that can `}<strong parentName="p">{`discover images`}</strong>{` in the magazine and then play
the `}<strong parentName="p">{`related videos`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      